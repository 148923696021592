import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { CheckPointComponent } from './check-point/check-point.component';
import { ResponsePayComponent } from './response-pay/response-pay.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'campaigns',
    loadChildren: () =>
      import('./campaigns/campaigns.module').then((m) => m.CampaignsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'contacts',
    loadChildren: () =>
      import('./contacts/contacts.module').then((m) => m.ContactsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'plans',
    loadChildren: ()=> 
      import('./plans/plans.module').then((m)=> m.PlansModule ),
    canActivate: [AuthGuard]
  },
  {
    path: 'historic',
    loadChildren: () =>
      import('./historic/historic.module').then((m) => m.HistoricModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'response-pay',
    component: ResponsePayComponent
  },
  {
    path: 'c/:data',
    component: CheckPointComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
