/* eslint-disable new-parens */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { switchMap, take, tap } from 'rxjs/operators';
import { FireModel } from './fire-model.module';
import { Message } from './message.model';
import { EMPTY, from } from 'rxjs';

export class MessageGroup extends FireModel {
  _id: string;
  _campaignId: string;
  numGroup: number;
  nextRow: number;
  rows: Message[];
  status: number;
  date: string;

  constructor(data = null) {
    super('messages', data);
    if (!this.date) {
      this.date = MessageGroup.getCurrentDate();
    }
    this.rows = [];
  }

  getPath(): string {
    return super
      .getPath()
      .replace('/messages', `/campaigns/${this._campaignId}/messages`);
  }

  getByCampaignIdAndStatus(campaignId: string, status: number) {
    this._campaignId = campaignId;
    const path = this.getPath();
    return FireModel.db.firestore
      .collection(path)
      .where('status', '==', status)
      .orderBy('numGroup')
      .limit(1)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          const data = new (this.constructor as any)().fromJSON(doc.data());
          data._id = doc.id;
          data._campaignId = campaignId;
          return data;
        }
        return null;
      });
  }

  getOutdatedState(campaignId: string) {
    this._campaignId = campaignId;
    const path = this.getPath();
    return FireModel.db.firestore
      .collection(path)
      .where('status', '<', Message.STATUS_READ)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          return querySnapshot.docs.map((doc) => {
            const data = new (this.constructor as any)().fromJSON(doc.data());
            data._id = doc.id;
            data._campaignId = campaignId;
            return data;
          });
        }
        return [];
      });
  }
  //No Auth
  async changeStateAndValueGroup(
    user_id: string,
    wpp_user: string,
    campaign_id: string,
    num_group: number,
    num_row: number,
    url: string
  ) {
    const path = `/wapp/${user_id}/accounts/${wpp_user}/campaigns/${campaign_id}/messages/${num_group}`;
    const documentRef = FireModel.db.doc(path);
    try {
      documentRef
        .valueChanges()
        .pipe(
          take(1),
          switchMap((doc: { rows?: any[]; links_viewed?: any[] }) => {
            if (!doc) {
              console.log('El documento no existe.');
              return EMPTY;
            }
            doc.rows = doc.rows || [];
            doc.rows[num_row] = doc.rows[num_row] || {};
            doc.rows[num_row].links_viewed =
              doc.rows[num_row]?.links_viewed || [];
            doc.rows[num_row].status = Message.STATUS_CLICKED;

            if (!doc.rows[num_row].links_viewed.includes(url)) {
              doc.rows[num_row].links_viewed.push(url);
            }
            return from(documentRef.update(doc));
          })
        )
        .subscribe(
          () => {
            console.log('Documento actualizado exitosamente.');
          },
          (error) => {
            console.log(error);
            console.error('Error al obtener o actualizar el documento:', error);
          }
        );
    } catch (error) {
      console.log(error);
    }
  }

  fromJSON(group: any) {
    group = super.fromJSON(group);
    if (!this.rows) {
      this.rows = [];
    }
    group.rows = this.rows.map((r) => new Message().fromJSON(r));
    return group;
  }

  toJSON() {
    const data: any = super.toJSON();
    if (!data.rows) {
      data.rows = [];
    }
    data.rows = data.rows.map((m) => m.toJSON());
    return data;
  }

  /*
    getOutdatedState(){
        return Message.dbService.getAllByIndex(this.store, 'message_status',
            IDBKeyRange.bound(Message.STATUS_SENT, Message.STATUS_RECEIVED, false, false))
            .toPromise()
            .then((messages: any[])=>{
                const status = {};
                for(const message of messages){
                    status[message.wp_id] = message.status-Message.STATUS_SENT+1;
                }
                return status;
            });
    }
    */
}
