/* eslint-disable new-parens */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { FireModel } from './fire-model.module';
import { MessageGroup } from './message-group.model';

export class Campaign extends FireModel {
  static STATUS_CREATED = 1;
  static STATUS_SENT = 2;
  static STATUS_PAUSED = 3;
  static STATUS_FINISHED = 4;
  static STATUS_DELETED = 5;
  static LIMIT = 20; //100

  _id: string;
  name: string;
  messages: string[];
  credits: number;
  numGroups: number;
  numRows: number;
  nextRow: number;
  limit: number;
  fields: string[];
  status: number;
  date: string;
  wpp_links: string;

  constructor(data = null) {
    super('campaigns', data);
    if (!this.date) {
      this.date = Campaign.getCurrentDate();
    }
  }

  async getMessages() {
    const messageGroup = new MessageGroup();
    messageGroup._campaignId = this._id;
    const messages = await messageGroup.getAll();
    return messages.reduce((acc, val) => acc.concat(val.rows), []);
  }

  async getMessageById(id: string) {
    const path = this.getPath();
    const snapshot = await FireModel.db.firestore
      .collection(path)
      .doc(id)
      .get();
    const data = snapshot.data();
    return { name: data.name, message: data?.messages ?? null, date: data.date };
  }

  addLinkInCampaing(campaignId: string) {
    return new Campaign().addLinkCampaing(campaignId); // Add wpp_link in key campaing
  }
}
