import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckPoint } from '../models/model.interfaces';
import { FireModel } from '../models/fire-model.module';
import { switchMap, tap } from 'rxjs/operators';
import { MessageGroup } from '../models/message-group.model';

@Component({
  selector: 'app-check-point',
  templateUrl: './check-point.component.html',
  styleUrls: ['./check-point.component.scss'],
})
export class CheckPointComponent implements OnInit {
  checkPointData: CheckPoint;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    let setData = this.route.snapshot.params['data'];
    const wppLinkId = setData.substr(0, 5);
    const posLink = this.getCharacterAtPosition(setData, 5);
    const numGroup = this.getCharacterAtPosition(setData, 6);
    const numRow = this.getCharacterAtPosition(setData, 7);
    this.locationHref(wppLinkId, posLink, numGroup, numRow);
  }

  getCharacterAtPosition(str: string, position: number) {
    return parseInt(str[position]);
  }

  locationHref(wpp_id: string, posLink: number, numGroup: number, numRow: number) {
    const collection = FireModel.db.collection('wapp-links').doc(wpp_id);

    collection
      .valueChanges()
      .pipe(
        tap((doc) => {
          if (!doc) {
            console.log('El documento no existe.');
          }
        }),
        switchMap((doc) => {
          const url = doc ? doc['url'] : null;
          const doc_id = doc['campaign_id'];
          const user_id = doc['user'];
          const wpp_account = doc['wp_account'];
          
          const data = {
            user: user_id,
            wpp_account: wpp_account,
            campaign_id: doc_id,
            url: url ? url[posLink] : null
          };
          return [data];
        })
      )
      .subscribe(
        async (data) => {
          const {user, wpp_account, campaign_id, url} = data;
          await new MessageGroup().changeStateAndValueGroup(user, wpp_account, campaign_id, numGroup, numRow, url)
          
          setTimeout(() => {
            window.location.href = url;
          }, 500);
        },
        (error) => {
          console.log(error);
          console.error('Error al obtener el documento:', error);
        }
      );
  }

}
