import { Component, OnInit } from '@angular/core';
import { Settings } from './models/settings.model';
import { AppControllerService } from './services/app-controller.service';
import { AuthService } from './services/auth.service';
import { ShowMenuService } from './response-pay/show-menu.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    { title: 'Campañas', url: '/campaigns', icon: 'logo-whatsapp' },
    { title: 'Mis Campañas', url: '/contacts', icon: 'id-card-outline' },
    // { title: 'Planes', url: '/plans', icon: 'cash-outline' },
    { title: 'Histórico', url: '/historic', icon: 'server-outline' },
    { title: 'Configuración', url: '/settings', icon: 'settings-outline' },
  ];
  wpAccount: any;
  user: any = null;
  subscription: any = null;
  showMenu = false;

  constructor(
    private appController: AppControllerService,
    private authservice: AuthService,
    private showMenuService: ShowMenuService
  ) {
    appController.wpAccountS.subscribe((wpAccount) => {
      this.wpAccount = wpAccount;
    });

    this.authservice.getAuthStateSub().subscribe((user) => (this.user = user));
    appController.subscriptionS.subscribe((s) => (this.subscription = s));
    //Loading settings
    /*
    new Settings().count().subscribe((numSettings) => {
      if (numSettings === 0) {
        Settings.getDefault().save().subscribe(() => {
          console.log('Configuración creada');
        });
      }
    });*/
  }
  ngOnInit(): void {
    this.showMenuService.showMenu$.subscribe((showMenu) => {
      this.showMenu = showMenu;
    });
  }

  getPhone(wid) {
    return wid.split('@')[0];
  }

  openInfo(codError) {
    let message;
    this.appController.updateAccount();
    if (codError === 1) {
      message =
        'Por favor revise que WhatsApp Web esté abierto en el navegador y conectado a su teléfono.';
    } else {
      message = 'Por favor revise que su teléfono esté conectado a internet.';
    }

    this.appController.showError(message);
  }

  logout() {
    this.authservice.signOut();
  }
}
