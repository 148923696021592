/* eslint-disable @typescript-eslint/naming-convention */
import { FireModel } from './fire-model.module';

export class Subscription extends FireModel {
  _id: string;
  consumed_credits: number;
  creation: number;
  credits: number;
  expiration: number;
  limit: number;
  plan: number;

  constructor(data = null) {
    super('subscription', data);
  }

  getPath(): string {
    return `wapp-subscriptions`;
  }

  getSubscriptionById(id: string) {
    const path = this.getPath();
    console.log(path);
    return FireModel.db.firestore
      .collection(path)
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = new (this.constructor as any)().fromJSON(doc.data());
          data._id = doc.id;
          return data;
        }
        return null;
      });
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  static fromJSON(data: any) {
    const contact = new Subscription();
    Object.assign(contact, data);
    return contact;
  }

  async getDataHistoric(id: string) {
    const path = `${this.getPath()}/${id}/historic`;
    const snapshot = await FireModel.db.firestore.collection(path).get();
    const data = snapshot.docs.map((doc) => {
      const SubscriptionClass = this.constructor as typeof Subscription;
      return SubscriptionClass.fromJSON(doc.data());
    });
    return data;
  }

  async postPayHistoric(data: any) {
    const path = `/wapp-subscriptions/${data.uid}/historic_pay`;
    try {
      const snapshot = FireModel.db.firestore.collection(path);
      const existingDocument = await snapshot
        .where('ref_epayco', '==', data.ref_epayco)
        .get();

      if (existingDocument.empty) {
        await snapshot.add(data);
      }
    } catch (error) {
      console.log(error);
      console.error('Error al intentar agregar el pago histórico:', error);
      throw error; // Puedes elegir manejar el error de alguna otra manera según tus necesidades.
    }
  }
}
