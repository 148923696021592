/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShowMenuService } from './show-menu.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import axios from 'axios';
import { Subscription } from '../models/subscription.model';

@Component({
  selector: 'app-response-pay',
  templateUrl: './response-pay.component.html',
  styleUrls: ['./response-pay.component.scss'],
})
export class ResponsePayComponent implements OnInit {
  showMenu = false;
  date: string;
  response: string;
  reference: string;
  reason: string;
  receipt: string;
  bank: string;
  authorization: string;
  total: string;

  constructor(
    private router: Router,
    private showMenuService: ShowMenuService,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const refPayco = params['ref_payco'];
      const ref_uid = params['uid'];
      const ref_plan = params['plan_id'];
      const urlApp = `https://secure.epayco.co/validation/v1/reference/${refPayco}`;

      this.http.get(urlApp).subscribe(async (response: any) => {
        if (response.success) {
          const data = {
            uid: ref_uid,
            plan_id: ref_plan,
            ref_epayco_key: refPayco,
            ref_epayco: response.data.x_id_invoice,
            date: response.data.x_transaction_date,
            total_pay: response.data.x_amount,
            status_pay: '',
          };

          this.date = response.data.x_transaction_date;
          this.response = response.data.x_response;
          this.reference = response.data.x_id_invoice;
          this.reason = response.data.x_response_reason_text;
          this.receipt = response.data.x_transaction_id;
          this.bank = response.data.x_bank_name;
          this.authorization = response.data.x_approval_code;
          this.total =
            response.data.x_amount + ' ' + response.data.x_currency_code;

          //Transaccion Aprobada
          if (response.data.x_cod_response === 1) {
            data.status_pay = 'Approved';
            await new Subscription().postPayHistoric(data);
          }
          //Transaccion Rechazada
          if (response.data.x_cod_response === 2) {
            data.status_pay = 'Rejected';
            await new Subscription().postPayHistoric(data);
          }
          //Transaccion Pendiente
          if (response.data.x_cod_response === 3) {
            data.status_pay = 'Pending';
            await new Subscription().postPayHistoric(data);
          }
          //Transaccion Fallida
          if (response.data.x_cod_response === 4) {
            data.status_pay = 'Failed';
            await new Subscription().postPayHistoric(data);
          }
        }
      });
    });

    const fullPath = this.router.routerState.snapshot.url;
    this.showMenu = fullPath.includes('response-pay');
    this.showMenuService.setShowMenu(this.showMenu);
  }

  printPage() {
    window.print();
  }
}
