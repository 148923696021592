/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShowMenuService {
  private showMenuSource = new BehaviorSubject<boolean>(false);
  showMenu$ = this.showMenuSource.asObservable();

  setShowMenu(value: boolean) {
    this.showMenuSource.next(value);
  }
}
